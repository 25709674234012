import { useState } from 'react'
import { useSelector } from "react-redux"
import { FaFilter } from 'react-icons/fa'
import Header from '../components/base/Header'
import Info from '../components/Cyberdash/Info'
import DisclosurePolicy from '../components/Vulnerabilities/DisclosurePolicy'
import Tools from '../components/Vulnerabilities/Tools'
import VulnerabilityTable from '../components/Vulnerabilities/VulnerabilityTable'

const Vulnerabilities = () => {
    const description = {
        "text": "All Vedere Labs discovered CVEs and relevant info about each one."
    }
    const { threat_dashboard } = useSelector((state) => state.data)

    const [sorting, setSorting] = useState({ field: 'date', ascending: false })
    const [data, setData] = useState(threat_dashboard['Vuln_Table']);

    const applySorting = (key, ascending) => {
        setSorting({ field: key, ascending: ascending })

        const dataCopy = [...data];

        const sortedData = dataCopy.sort((a, b) => {
            if (typeof (a[key]) === "string" && key !== "date") {
                return a[key].localeCompare(b[key]);
            }
            else if (key === 'date') {
                a = a[key].split("/")
                a = `${a[2]}${a[0]}${a[1]}`
                b = b[key].split("/")
                b = `${b[2]}${b[0]}${b[1]}`

                return a > b ? 1 : a < b ? -1 : 0;
            }
            else {
                return a[key] - b[key];
            }
        })

        setData(ascending ? sortedData : sortedData.reverse())
    }

    const filterData = (value) => {
        const dataCopy = threat_dashboard['Vuln_Table'];

        const filtered = dataCopy.filter(dataCopy => dataCopy['bundle'].includes(value))

        setData(filtered)
    }

    const resetData = () => {
        setData(threat_dashboard['Vuln_Table'])
    }

    return (
        <main>
            <Header text={"Vulnerability Disclosure Dashboard"} />
            <section className='background-image'>
                <div className="updated-box">
                    <Info description={description} />
                    <h2 className='vuln-header'>Disclosed Vulnerabilities</h2>
                    <h3 className="subtitle">Original Vulnerability found and disclosed by Vedere Labs</h3>
                    <h3 className="subtitle">Find out more info about all of our vulnerabilities <a href="https://www.forescout.com/research-labs-overview/" target="_blank">here</a></h3>
                    <div className="quick_filters">
                        <div>
                            <FaFilter />
                            <p>Quick Filters:</p>
                        </div>
                        <button onClick={() => resetData()}>All</button>
                        <button onClick={() => filterData('Sierra:21')}>Sierra:21 </button>
                        <button onClick={() => filterData('OT:ICEFALL')}>OT:ICEFALL </button>
                        <button onClick={() => filterData('BGP')}>BGP </button>
                        <button onClick={() => filterData('Access:7')}>Access:7 </button>
                        <button onClick={() => filterData('NUCLEUS:13')}>NUCLEUS:13 </button>
                        <button onClick={() => filterData('NAME:WRECK')}>NAME:WRECK </button>
                        <button onClick={() => filterData('NUMBER:JACK')}>NUMBER:JACK </button>
                        <button onClick={() => filterData('INFRA:HALT')}>INFRA:HALT </button>
                        <button onClick={() => filterData('AMNESIA:33')}>AMNESIA:33 </button>
                    </div>
                    <VulnerabilityTable sorting={sorting} applySorting={applySorting} data={data} />
                    <div className='boxes'>
                        <Tools />
                        <DisclosurePolicy />
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Vulnerabilities